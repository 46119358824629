import React, { useEffect, useState, useMemo, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, Select, InputNumber, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput, FileInput, GroupPicker, FileInputSingle, LangPicker, SendNotificationInput, FileInputSingleArray } from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)


const Content =({data,errors,quillRef,modulesT,formats,setData,languagesCms})=>(
  <Row direction="row" >
    <Col span={24}>
      <Form.Item label={languagesCms.CONTENTS} required={(data.type === 'Content' || data.type === 'SliderContent') ? true : false} help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
        <ReactQuill
          ref={quillRef}
          modules={modulesT}
          formats={formats}
          name="content" theme="snow" value={(data.content) ? data.content : ''} onChange={content => setData({ ...data, content: content })} />
      </Form.Item>
    </Col>
  </Row> 
)
const Media = ({data,setData,errors,languagesCms}) => (
  <Row direction="row">
    <Col span={12}>
      <Form.Item label={languagesCms.MEDIA} required={(data.type === 'Image' || data.type === 'SliderContent') ? true : false} help={errors.medias} validateStatus={errors.medias ? 'error' : 'success'}>
        {(data.listView === true && ( data.medias && data.medias.length > 0)) && ( <span style={{ backgroundColor:"gold", padding: "3px", borderRadius:"5px"}}>{languagesCms.PREVIEW}</span>)}
        <FileInput languagesCms={languagesCms} name='medias' title='Medyalar' type='file' multi={false} ext={['image', 'video']} record={data} setRecord={setData} />
        {(data.type === "SliderContent" || data.type === 'ImageContent' || data.type === 'Multiple' || data.type === 'Multipager') && (<Alert banner message={languagesCms.FIRST_IMAGE_WILL_BE_PREVIEW} />)}
        <Alert message={languagesCms.MEDIA_MESSAGE} banner />
      </Form.Item>
    </Col>
  </Row>
)

const SingleImage = ({data,setData,errors,languagesCms}) => (
  <Row direction="row">
    <Col span={12}>
      <Form.Item label={languagesCms.MEDIA} required={(data.type === 'Image' || data.type === 'SliderContent') ? true : false} help={errors.medias} validateStatus={errors.medias ? 'error' : 'success'}>
        <FileInputSingleArray languagesCms={languagesCms} name='medias' title='Medyalar' type='file' multi={false} record={data} setRecord={setData} />
        <Alert message={languagesCms.MEDIA_MESSAGE} banner />
      </Form.Item>
    </Col>
  </Row>
)

const Coordinate = ({data,setData, errors,languagesCms}) => (
  <Row direction="row">
  <Col span={24}>
    <Form.Item  label={languagesCms.LOCATION} required={(data.type === 'Map') ? true : false} help={errors.coordinate} validateStatus={errors.coordinate ? 'error' : 'success'} >
      <MapInput languagesCms={languagesCms} record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" />
    </Form.Item>
  </Col>
</Row>
)

const Pdf = ({data,setData,errors}) => (  
 <Row direction="row">
    <Col span={24}>
      <Form.Item label="PDF" required={data.type === 'PDF' ? true : false} help={errors.pdf} validateStatus={errors.pdf ? 'error' : 'success'}>
        <FileInputSingle name='pdf' fileType='pdf' title='Medyalar' type='file' multi={false} record={data} setRecord={setData} />
      </Form.Item>
    </Col>
  </Row>
)

const ImagePrewiev = ({data,setData, errors, languagesCms}) => (
  <Row>
    <Col xs={{ span: 24 }} md={{ span: 12 }}>
      <Form.Item label={languagesCms.PICTURE_VIEW} help={errors.listView} validateStatus={errors.listView ? 'error' : 'success'}>
        <Switch checked={data.listView ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, listView: v })} />
      </Form.Item>
    </Col>
  </Row>
)

const InformationDetail = (props) => {

  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter(el => el.refId === path);

  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let [langCms,] = useGlobal("langCms")
  let [langCode] = useGlobal("langCode"); 


  let newRecord = {
    medias: [],
    lang: langCode ? langCode : langCms ? langCms[0].code : "TR",
    order: 0,
    header: '',
    type: '',
    groups: [],
    module: module ? module._id : ''
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  //onetime run
  useEffect(() => {
    if (id) {
      api.get("/rest/informations/" + id).then(({ data: { result, result_message } }) => {
        if(module){
          result.module = module._id;}
          setData(result);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    if (data.lang === null)
      errors.lang = languagesCms.REQUIRED

    if (data.order === null)
      errors.order = languagesCms.REQUIRED

    if (data.header === null || data.header.length === 0)
      errors.header = languagesCms.REQUIRED

    if (data.type === null || data.type.length === 0)
      errors.type = languagesCms.REQUIRED

    if ((data.type === 'SliderContent') && (!data.content || data.content === null))
      errors.content = languagesCms.REQUIRED

    if ((data.type === 'Image' || data.type === 'SliderContent') && (data.medias === null || data.medias.length === 0))
      errors.medias = languagesCms.REQUIRED

    if (data.type === 'PDF' && (data.pdf === null || !data.pdf))
      errors.pdf = languagesCms.REQUIRED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">"
        if(data.content){
          data.content = iosFormat + data.content
        }

        if ( data.listView === true && data.medias.length === 0 ) {
          data.medias = null
        }
        if (data.type === "PDF") {
          data.medias = data.medias.length === 0 ? [] : data.medias
        }
        if (data.type === 'SliderContent' && data.medias.length === 1) {
          data.type = 'Image'
        }
        if (data.type === 'Image' && data.medias.length > 1) {
          data.type = 'SliderContent'
        }
        if (data.type === 'ImageContent' && data.content === undefined) {
          data.type = 'Image'
        }
        if (data.type === 'ImageContent' && ( data.medias && data.medias.length > 1)) {
          data.type = 'SliderContent'
        }
        if (data.type === 'ImageContent' && ( data.medias === undefined || data.medias === null || (data.medias && data.medias.length === 0)) ) {
          data.type = 'Content'
        }
        if (data.type === 'MapContent' && data.content === undefined) {
          data.type = 'Map'
        }
        if (data.type === 'MapContent' && data.content.length > 1 && data.coordinate === undefined) {
          data.type = 'Content'
        }
        
        if (data.header === " " || data.header === " ") data.header = ""
        api.put("/rest/informations/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push("/" + module.refId)
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><style>*{font-family: '-apple-system','HelveticaNeue'; font-size:14px;}</style>"
        if(data.content){
          data.content = iosFormat + data.content
        }
        if ( data.listView === true && data.medias.length === 0 ) {
          data.medias = null
        }
        if (data.type === 'ImageContent' && data.medias.length > 1) {
          data.type = 'SliderContent'
        }
        if (data.type === "PDF") {
          data.medias = data.medias.length === 0 ? [] : [data.medias]
        }
        if (data.type === "Multiple") {
          data.medias = data.medias.length === 0 ? [] : [data.medias]
        }
        if (data.type === 'ImageContent' && data.content === undefined) {
          data.type = 'Image'
        }
        if (data.type === 'ImageContent' && data.medias.length === 0 ) {
          data.type = 'Content'
        }
        if (data.type === 'MapContent' && data.content === undefined) {
          data.type = 'Map'
        }
        if (data.type === 'MapContent' && data.content.length > 1 && data.coordinate === undefined) {
          data.type = 'Content'
        }
        
        if (data.header === " " || data.header === " ") data.header = ""
        
        api.post("/rest/informations", data).then(({ data: { result, result_message } }) => {
          
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push("/" + module.refId)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  let imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {

      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      // Save current cursor state
      const range = quill.getSelection(true);

      // // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
      // Move cursor to right side of image (easier to continue typing)
      quill.setSelection(range.index + 1);

      let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data' } }).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          return result[0].url;
        }
      });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  }

  const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
  const modulesT = useMemo(() => ({
      toolbar: {
          container: [
              [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              ['link', 'image', 'video'],
              [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { align: [] },
                  { direction: 'rtl' },
                ],
              ['clean'],
              ['code-block'],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{ 'color': [] }, { 'background': [] }],
              [{ script: 'sub' }, { script: 'super' }, 'formula']
          ],
          handlers: {
              image: imageHandler
          }
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          modules: ['Resize', 'DisplaySize']
      }
  }), [])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/" + (module ? module.refId : "")}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.LANG}>
                    <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.GROUP} help={errors.groups} validateStatus={errors.active ? 'error' : 'success'}>
                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.TYPE} required help={errors.type} validateStatus={errors.type ? 'error' : 'success'}>
                    <Select defaultValue={(data.type) ? data.type : languagesCms.SELECT_TYPE} style={{ width: 250 }} onChange={v => { setData({ ...data, type: v }) }}>
                      <Select.Option value="PDF">PDF</Select.Option>
                      <Select.Option value="Multiple">{languagesCms.CATEGORY}</Select.Option>
                      <Select.Option value="Multipager">{languagesCms.TABS}</Select.Option>
                      <Select.Option value="MapContent">{languagesCms.MAP_CONTENT}</Select.Option>
                      <Select.Option value="ImageContent">{languagesCms.PICTURE_CONTENT}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                
                {data.type !== "Multiple" && data.type !== "Multipager" &&
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={languagesCms.NOTIFICATION} help={errors.sendNotification} validateStatus={errors.sendNotification ? 'error' : 'success'}>
                      <SendNotificationInput record={data} setRecord={setData} name="sendNotification" languagesCms={languagesCms}/>
                    </Form.Item>
                  </Col>
                }
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.TITLE} required help={errors.header} validateStatus={errors.header ? 'error' : 'success'}>
                    <Input name="header" value={data.header} onChange={e => setData({ ...data, header: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.ORDER} required validateStatus={errors.name ? 'error' : 'success'}>
                    <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                  </Form.Item>
                </Col>
              </Row>
              {data.type === "" &&(
                  <>
                  <Content  languagesCms={languagesCms} data={data} setData={setData} errors={errors} quillRef={quillRef}  modulesT={modulesT} formats={formats}/><ImagePrewiev languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  <Coordinate  languagesCms={languagesCms} data={data} setData={setData} errors={errors}  />
                  <Media  languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  <Pdf  languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  </>
                )}
                 {data.type === "Content" &&(
                  <>
                  <Content  languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  </>
                )}
                {data.type === "PDF" &&(
                  <>
                  <Pdf  data={data} setData={setData} errors={errors} />
                  <ImagePrewiev languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  {data.listView && (
                    <>
                      <SingleImage  languagesCms={languagesCms} data={data} multi={false} setData={setData} errors={errors} />
                    </>
                  )}
                  </>
                )}
                {data.type === "Image" &&(
                  <>
                    <Media  languagesCms={languagesCms} data={data} setData={setData} errors={errors} />c
                  </>
                )}
                {data.type === "Map" &&(
                  <>
                  <Coordinate  languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  </>
                )}
                {data.type === "Multiple" &&(
                  <>
                  <ImagePrewiev languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                   {data.listView && (
                    <>
                      <SingleImage  languagesCms={languagesCms} data={data} multi={false} setData={setData} errors={errors} />
                    </>
                  )}
                  </>
                )}
                {data.type === "Multipager" &&(
                  <>
                  <Media languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  <Pdf  data={data} setData={setData} errors={errors} />
                  </>
                )}
                {data.type === "MapContent" &&(
                  <>
                  <Content languagesCms={languagesCms} data={data} setData={setData} errors={errors} quillRef={quillRef}  modulesT={modulesT} formats={formats}/>
                  <Coordinate languagesCms={languagesCms} data={data} setData={setData} errors={errors}  />
                  </>
                )}
                {data.type === "ImageContent" &&(
                  <>
                  <Content languagesCms={languagesCms} data={data} setData={setData} errors={errors} quillRef={quillRef}  modulesT={modulesT} formats={formats}/>
                  <ImagePrewiev languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  <Media languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  </>
                )}
                {data.type === "SliderContent" &&(
                  <>
                  <Content languagesCms={languagesCms} data={data} setData={setData} errors={errors} quillRef={quillRef}  modulesT={modulesT} formats={formats}/>
                  <ImagePrewiev languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  <Media languagesCms={languagesCms} data={data} setData={setData} errors={errors} />
                  </>
                )}
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default InformationDetail;
