import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';


import {EditOutlined, DeleteOutlined, PlusOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";

const Information = (props) => {

    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [isSmall,] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let [module] = modules.filter(el => el.refId === path);
    const {Search} = Input;


    let handleTableChange = async (page, filters, sorter) => {

        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order,-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let query = [];
        query["module"] = module ? module._id : "";
        query["parent"] = null;

        let _params = {sort: shortString, ...page,query, module: (module ? module._id : "")};


        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let restData = await api.get(`/rest/informations?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    if (!item.hasOwnProperty('groups')) {
                        item.groups = []
                    }
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        get();
    }, [module]);

    useEffect(() => {
        get();
    }, [search]);
    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/informations/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/informations/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
    }

    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'header',
            key: 'header',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.LANG,
            dataIndex: 'lang',
            key: 'lang',
            sorter: (a, b) => a.lang - b.lang,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.ORDER,
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.TYPE,
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type - b.type,
            sortDirections: ['descend', 'ascend']
        },
        {
      title: languagesCms.GROUP,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (groups) => groups.length > 0 && groups.map(e => <div style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title: 'Aksiyonlar',
            key: 'action',
            align: 'right',
            className: 'editColumn',
            render: (text, record) => (
                <Space size="small" align="end">

                    {(record.type === "Multipager" || record.type === "Multiple") ? (
                        <Link to={"/" + (module ? module.refId : "") + "/detail/" + record._id}>
                            <Button>
                                {isSmall ? '' : languagesCms.DETAIL}
                            </Button>
                        </Link>
                    ) : (
                        ""
                    )}

                    <Link to={"/" + (module ? module.refId : "") + "/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>)}
                    <Link to={(module ? module.refId : "") + "/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default Information;
