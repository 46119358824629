import React, { useEffect, useState, useMemo, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message, InputNumber,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { SessionPicker, LangPicker, ImageUrl } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)

const SpeakerDetail = (props) => {
    const quillRef = useRef(null);

    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 

    let newRecord = {
        lang: langCode ? langCode : langCms ? langCms[0].code : "TR" ,
        name: '',
        order: 0,
        sessions: [],
        info: '',
        imageurl: '',
        company: "",
        position : "",
        sessionButtonBackgroundColor : ""
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
 
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if(modules !== null){
       [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
                api.get("/rest/speakers/" + id).then(({ data: { result, result_message } }) => {
                    setData(result);
                    setLoading(false)
                });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.imageurl === null)
            errors.active = languagesCms.ENFORCED

        if (data.name === null || data.name.length === 0)
            errors.name = languagesCms.ENFORCED

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            if (id) {
                api.put("/rest/speakers/" + id, data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/speakers')
                    }
                    else
                        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
            } else {
                api.post("/rest/speakers", data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/speakers')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    }; let imageHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {

            const quill = quillRef.current.getEditor();
            const file = input.files[0];
            const formData = new FormData();

            formData.append('files_0', file);

            // Save current cursor state
            const range = quill.getSelection(true);

            // // Insert temporary loading placeholder image
            quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
            // Move cursor to right side of image (easier to continue typing)
            quill.setSelection(range.index + 1);
        
            let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'success') {
                return result[0].url;
                }
            });
            quill.deleteText(range.index, 1);
            quill.insertEmbed(range.index, 'image', url);
        };
    }

    const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
    const modulesT = useMemo(() => ({
        toolbar: {
            container: [
                [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link', 'image', 'video'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { align: [] },
                    { direction: 'rtl' },
                  ],
                ['clean'],
                ['code-block'],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{ 'color': [] }, { 'background': [] }],
                [{ script: 'sub' }, { script: 'super' }, 'formula']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), [])

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/speakers">
<Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">

                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.LANG}>
                                    <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.SESSION}>
                                    <SessionPicker languagesCms={languagesCms} record={data} setRecord={setData} name="sessions" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.NAME_LASTNAME} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.ORDER} required help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                                    <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.COMPANY} help={errors.company} validateStatus={errors.company ? 'error' : 'success'}>
                                    <Input name="company" value={data.company} onChange={e => setData({ ...data, company: e.target.value })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.POSITION} help={errors.position} validateStatus={errors.position ? 'error' : 'success'}>
                                    <Input name="position" value={data.position} onChange={e => setData({ ...data, position: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label={languagesCms.EXPLANATION} help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <ReactQuill
                                        ref={quillRef}
                                        modules={modulesT}
                                        formats={formats}
                                        name="info" theme="snow" value={(data.info) ? data.info : ''} onChange={info => setData({ ...data, info: info })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label={languagesCms.PHOTO} required help={errors.imageurl} validateStatus={errors.imageurl ? 'error' : 'success'}>
                                    <ImageUrl record={data} setRecord={setData} name="imageurl" />
                                    <Alert  message={languagesCms.MEDIA_CONTENTS_MESSAGE} banner />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};



export default SpeakerDetail;
