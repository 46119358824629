import React, { useEffect, useState, useGlobal } from 'reactn';
import { Form, Button, Switch, message, Input, Typography, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { DragOutlined, LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
// C:\www\ICILETISIM\iciletisim-cms-new\src\service\index.js
import qs from 'qs';

const { TabPane } = Tabs;
const { Text } = Typography;

const Module = (props) => {
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  let [, setData] = useState([]);
  let [modules, setModules] = useState([]);
  let [search, setSearch] = useState(false);
  // let [modulesGlobal, setModulesGlobal] = useGlobal("modules");
  const { Search } = Input;
  let _params = { sort: 'order', page: 1, pageSize: 100 };
  let [languagesCms] = useGlobal('languagesCms');
  let [langs] = useGlobal("langs"); 

  /*let lang;
  if (langCode || langCms) {
    lang = langCode ? langCode : langCms[0].code;
  }
  let query = {
    lang: lang ? lang : 'TR',
  };*/
  let getResponse = async () => {
    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }
    await api.get(`/rest/modules?${qs.stringify(_params)}`).then(({ data }) => {
      setModules(data.result.rows);
    });
  };

  // let getModulesLocalStorage = async () => {
  //   api.get(`/rest/modules?${qs.stringify(query)}`).then(({ data }) => {
  //     console.log("data");
  //     if (data.result.rows) {
  //       console.log([...data.result.rows]);
  //         // setModulesGlobal([...data.result.rows]);
  //         // localStorage.setItem("modules", JSON.stringify([...data.result.rows]));
  //         window.location.reload();
  //     }
  //   })
  // }

  useEffect(() => {
    getResponse();
  }, [search]);
  let onChange = async (e) => {
    setSearch(e.target.value);
    getResponse();
  };

  let saveOrders = async () => {
    modules.forEach((item, index) => {
      item.order = index;
      api.put('/rest/modules/' + item._id, item).then((result) => {
        // getModulesLocalStorage()
      });
    });
    message.success(languagesCms.SUCCESS_SAVED_ORDER, 2);
  };

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: modules,
    });

    event.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (event) => {
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;

    const draggedFrom = dragAndDrop.draggedFrom;

    const draggedTo = Number(event.currentTarget.dataset.position);

    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom,
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  console.log(modules);

  const onDrop = (event) => {
    setModules(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };
  let onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };
  React.useEffect(() => {}, [dragAndDrop]);
  React.useEffect(() => {}, [modules]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{languagesCms.MODULES}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <div>
          <Search
            placeholder={languagesCms.SEARCH}
            onChange={onChange}
            onSearch={(v) => {
              setSearch(v);
              getResponse();
            }}
          />
        </div>
        <ul className="dragDrop moduleLiHover">
          <li>
            <div className="dragDrop">
              <Text style={{ marginRight: '2rem', fontWeight: '700' }}>
              {languagesCms.ORDER}
              </Text>
              <Text style={{ marginLeft: '3.5rem', fontWeight: '700' }}>
                {languagesCms.NAME_MODUL}
              </Text>
            </div>

            <div className="rightButtons">
              <Text style={{ marginRight: '2.4rem', fontWeight: '700' }}>
                Admin
              </Text>
              <Text style={{ marginRight: '2.3rem', fontWeight: '700' }}>
                {languagesCms.APPLICATION}
              </Text>
              <Text style={{ marginRight: '2.3rem', fontWeight: '700' }}>
                Web
              </Text>
              <Text style={{ marginRight: '1.5rem', fontWeight: '700' }}>
                {languagesCms.EDIT}
              </Text>
            </div>
          </li>

          <React.Fragment>
            {langs.length > 0 && (
              <Tabs defaultActiveKey={1} centered>
                {langs.map(( c, key ) => (
                <TabPane tab={c._id}key={key}>
                  {modules.map((item, index) => {
                    if (item.lang === c._id) {
                      return (
                        <li
                          key={index}
                          data-position={index}
                          draggable
                          onDragStart={onDragStart}
                          onDragOver={onDragOver}
                          onDrop={onDrop}
                          onDragLeave={onDragLeave}
                          className={
                            dragAndDrop && dragAndDrop.draggedTo === Number(index)
                              ? 'dropArea'
                              : ''
                          }
                        >
                          <div>
                            <Text
                              style={{
                                color: '#1890ff',
                                fontSize: '1.25rem',
                                marginRight: '1rem',
                              }}
                            >
                              {' '}
                              {item.order}
                            </Text>
                            <DragOutlined style={{ fontSize: 20 }} />
                            <span
                              style={{ marginLeft: '1.5rem', marginTop: '0.3rem' }}
                              className="nameDrag"
                            >
                              {item.name}
                            </span>
                            <Text
                              style={{
                                fontSize: '1.15rem',
                                color: '#1890ff',
                                marginRight: '1rem',
                                marginLeft: '1.5rem',
                              }}
                            >
                              {item.lang}
                            </Text>
                          </div>

                          <div className="rightButtons">
                            <Switch
                              checked={item.adminShow ? true : false}
                              checkedChildren={languagesCms.ACTIVE}
                              unCheckedChildren={languagesCms.PASSIVE}
                              onChange={(v) => {
                                if (!modules[index]) return;
                                modules[index].adminShow = v;
                                setData([...modules]);
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Switch
                              checked={item.appShow ? true : false}
                              checkedChildren={languagesCms.ACTIVE}
                              unCheckedChildren={languagesCms.PASSIVE}
                              onChange={(v) => {
                                if (!modules[index]) return;
                                modules[index].appShow = v;
                                setData([...modules]);
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Switch
                              checked={item.webShow ? true : false}
                              checkedChildren={languagesCms.ACTIVE}
                              unCheckedChildren={languagesCms.PASSIVE}
                              onChange={(v) => {
                                if (!modules[index]) return;
                                modules[index].webShow = v;
                                setData([...modules]);
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                              <Link to={'/modules/' + item._id}>
                                <Button
                                  type="primary"
                                  size="medium"
                                  onClick={scrollTop}
                                >
                                  {' '}
                                  {languagesCms.EDIT}
                                </Button>
                              </Link>
                            </span>
                          </div>
                        </li>
                      );
                    }
                    else return true
                  })}
                </TabPane>
              ))}
              </Tabs>
            )}
          </React.Fragment>
        </ul>
        <Form.Item>
          <Button type="primary" size="large" block onClick={saveOrders}>
            {' '}
            {languagesCms.SAVE_ORDER}{' '}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export default Module;
