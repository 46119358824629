import React, { useEffect, useState, useMemo, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput, GroupPicker, SendNotificationInput, LangPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)

const OpenPositionsDetail = (props) => {
  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let [languagesCms] = useGlobal("languagesCms"); 
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode"); 

  let newRecord = {
    lang: langCode ? langCode :  langCms ? langCms[0].code : "TR" ,
    name: '',
    description: '',
    active: true,
    sendNotification: false,
    releaseDate: new Date(),
    joinEndDate: new Date(),
    groups: []
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/rest/openPositions/" + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false)
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang === null)
      errors.lang = languagesCms.ENFORCED

    if (data.active === null)
      errors.active = languagesCms.ENFORCED

    if (data.sendNotification === null)
      errors.sendNotification = languagesCms.ENFORCED

    if (data.description === null || data.description.length === 0)
      errors.description = languagesCms.ENFORCED

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED

    if (data.releaseDate === null)
      errors.releaseDate = languagesCms.ENFORCED

    if (data.joinEndDate === null)
      errors.joinEndDate = languagesCms.ENFORCED

    


    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">"
        if(data.content){
          data.content = iosFormat + data.content
        }
        api.put("/rest/openPositions/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/openPositions')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><style>*{font-family: '-apple-system','HelveticaNeue'; font-size:14px;}</style>"
        if(data.content){
          data.content = iosFormat + data.content
        }
        api.post("/rest/openPositions", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/openPositions')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

 let imageHandler = () => {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      // Save current cursor state
      const range = quill.getSelection(true);

      // // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
      // Move cursor to right side of image (easier to continue typing)
      quill.setSelection(range.index + 1);
   
      let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          return result[0].url;
        }
      });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  }

  const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
  const quillmodules = useMemo(() => ({
      toolbar: {
          container: [
              [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              ['link', 'image', 'video'],
              [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { align: [] },
                  { direction: 'rtl' },
                ],
              ['clean'],
              ['code-block'],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{ 'color': [] }, { 'background': [] }],
              [{ script: 'sub' }, { script: 'super' }, 'formula']
          ],
          handlers: {
              image: imageHandler
          }
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          modules: ['Resize', 'DisplaySize']
      }
  }), [])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/openPositions">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">

        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.NOTIFICATION} required help={errors.sendNotification} validateStatus={errors.sendNotification ? 'error' : 'success'}>
                  <SendNotificationInput record={data} setRecord={setData} name="sendNotification" languagesCms={languagesCms}/>
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} required help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.SHOW_TIME}>
                  <Switch checked={data.showTime ? true : false} checkedChildren={languagesCms.SHOW} unCheckedChildren={languagesCms.DONTSHOW} onChange={v => setData({ ...data, showTime: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.RELEASE_DATE} required help={errors.releaseDate} validateStatus={errors.releaseDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.releaseDate)} onChange={v => setData({ ...data, releaseDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.APPLICATION_DEADLINE} required help={errors.joinEndDate} validateStatus={errors.joinEndDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.joinEndDate)} onChange={v => setData({ ...data, joinEndDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.EXPLANATION} required help={errors.description} validateStatus={errors.description ? 'error' : 'success'}>
                  <Input.TextArea name="description" value={data.description} onChange={v => setData({ ...data, description: v.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.CONTENTS} required help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
                  <ReactQuill
                    ref={quillRef}
                    modules={quillmodules}
                    formats={formats}
                    name="content" theme="snow" value={(data.content) ? data.content : ''} onChange={content => setData({ ...data, content: content })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.POSITION_OWNER}>
                  <Input name="speakers_names" value={data.speakers_names} onChange={v => setData({ ...data, speakers_names: v.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.LOCATION}>
                  <MapInput languagesCms={languagesCms} record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" required={false} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      </div>
    </div>
  );
};



export default OpenPositionsDetail;
