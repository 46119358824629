import React, { useEffect, useState } from "react";

import { useGoogleMaps } from "react-hook-google-maps";

import { Row, Col, Form, Input, Button, Popconfirm} from 'antd';

export default (props) => {

  let {record, setRecord} = props;
  
  let markers = [];

  let poly = null;
  

  let [mapCenter, ] = useState({ lat: 41.049531052374945, lng: 29.079319894358814 });
	let languagesCms = props.languagesCms;


  const { ref, map, google } = useGoogleMaps(
    // Use your own API key, you can get one from Google (https://console.cloud.google.com/google/maps-apis/overview)
    "AIzaSyC1NyStYSSVdxiUaiT26GNYb3jkN-W-Uq4",
    // NOTE: You should always set initial 'center' and 'zoom' values
    // even if you plan to change them later
    {
      center: mapCenter,
      zoom: 10,
    },
  );


  let reloadMap = () => {



    if (map) {
      markers = [];
      poly = new google.maps.Polyline({
       strokeColor: "green",
       strokeOpacity: 1.0,
       strokeWeight: 10,
       editable: true,
     });

     //center
     var bounds = new google.maps.LatLngBounds();
   
     if(record) {
      record.forEach((item) => {
        var myLatlng = new google.maps.LatLng(parseFloat(item.lat),parseFloat(item.lon))
        if(item.type === "line") {
          addPoly(myLatlng)
        } else if(item.type === "pin") { 
          addPin(myLatlng, item.name, item.content);
        }
        bounds.extend(myLatlng);
      })
      map.fitBounds(bounds);
     }
     
     

     //if (toggle) poly.setMap(null);
     google.maps.event.addListener(map, "click", function(event) {
       // var path = poly.getPath();
       // path.push(event.latLng);
       addPoly(event.latLng)
       addPin(event.latLng);
     });

     
     google.maps.event.addListener(poly, 'click', function(event) {
       addPin(event.latLng);
     });

     poly.setMap(map);
   

   }

  }
  useEffect(() => {
    
    reloadMap();
  }, [map, google]);
  


  let addPoly = (location) => {
    var path = poly.getPath();
    path.push(location);

    google.maps.event.addListener(path, "dragend", hesapla);
    google.maps.event.addListener(path, "insert_at", hesapla);
    google.maps.event.addListener(path, "remove_at", hesapla);
    google.maps.event.addListener(path, "set_at", hesapla);

  }

  let hesapla = () => {
    let points = [];
    var path = poly.getPath();
    for (var i = 0; i < path.length; i++) {
      var point = {
        type: "line",
        name: "",
        content: "",
        lat: path.getAt(i).lat().toString(),
        lon: path.getAt(i).lng().toString()
      };
      points.push(point);
    }


    for (var j = 0; j < markers.length; j++) {
      var pointer = {
        type: "pin",
        name: markers[j].name,
        content: markers[j].content,
        lat: markers[j].lat.toString(),
        lon: markers[j].lng.toString(),
      };
      points.push(pointer);
    }

    setRecord(points);
  }

  let addPin = (location, name = "", content = "") => {

    var square = {
      path: google.maps.SymbolPath.CIRCLE,
      strokeColor: "orange",
      fillColor: "orange",
      fillOpacity: 1,
      scale: 10,
      zIndex: 9999
    };

    var marker = new google.maps.Marker({
      position: location,
      map: map,
      icon: square,
      draggable: true,
      label: {
        text: (markers.length + 1).toString(),
        fontWeight: "bold"
      },
      zIndex: 9999
    }); 

    let markerPoint = {};
    markerPoint.lat = location.lat();
    markerPoint.lng = location.lng();
    markerPoint.name = name;
    markerPoint.content = content;
    markers.push(markerPoint);

    google.maps.event.addListener(marker, 'dragend', function(event) {
      markerPoint.lat = event.latLng.lat();
      markerPoint.lng = event.latLng.lng();
      hesapla();
    });



    hesapla();

  }

  let removeMarker = (key) => {
    let newRecord = record;
    delete newRecord[key];
    setRecord(newRecord);
    reloadMap();
  }

 let i = 0;
  return  (
    <div className="mapWrap" style={{ paddingBottom:40 }}>
      <div ref={ref} style={{ width: "100%", height: 300, marginButtom:30, border: "1px solid #d9d9d9" }} />

      
      <div style={{ padding: 30, background:"#F9FBFE", marginButtom:30, position:"relative" }}>
        {
        (record) &&
          record.map((item, key) => {
            if(item.type === "pin") {
              i++;
              return <Row key={key} direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item label={languagesCms.STATION+i}>
                    <Input name="name" value={item.name}  onChange={e => {
                      let newRecord = record;
                      newRecord[key].name = e.target.value
                      setRecord(newRecord);                      
                    }} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item>
                    <Input name="content" value={item.content} 
                    onChange={e => {
                      let newRecord = record;
                      newRecord[key].content = e.target.value
                      setRecord(newRecord);
                    }}  />
                    
                  </Form.Item>
                
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 2 }}>
                <Popconfirm 
                  onConfirm={() => removeMarker(key)} title={languagesCms.YOUR_ROUTE_ON_THE_MAP_WILL_ALSO_BE_DELETED}
                  okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                  <Button type="warning" htmlType="button" size="large" >
                    {languagesCms.DELETE}
                  </Button>
                </Popconfirm>
                </Col>
              </Row>
            }
            else return true
          })
        
       }    
        
      </div>
 


    </div>
  
  );
};

