import React, { useEffect, useState, useMemo, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, Alert} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { GroupPicker, BusinessCategoryPicker, CityPicker, FileInputSingle } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";

import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
Quill.register('modules/imageResize', ImageResize)

//langCode ? langCode :  langCms[0].code 

const CampaingDetail = (props) => {

  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;

  let newRecord = {
    name: '',
    active: true,
    startDate: new Date(),
    endDate: new Date(),
    groups: []
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
 
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/rest/campaings/" + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false)
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED

    if (data.category_id === null || data.category_id ===undefined )
      errors.category_id = languagesCms.ENFORCED

    if (data.media === null || data.media === undefined)
      errors.media = languagesCms.ENFORCED

    if (data.detailMedia === null || data.detailMedia === undefined)
      errors.detailMedia = languagesCms.ENFORCED


    if (data.cities === null || data.cities === undefined)
      errors.cities = languagesCms.ENFORCED

    if (data.startDate === null)
      errors.startDate = languagesCms.ENFORCED

    if (data.endDate === null)
      errors.endDate = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">"
        //data.sub_title ? data.sub_title = iosFormat+data.sub_title : data.sub_title= data.sub_title
        if(data.sub_title){
          data.sub_title = iosFormat+data.sub_title
        }
        api.put("/rest/campaings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/workfamily')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><style>*{font-family: '-apple-system','HelveticaNeue'; font-size:14px;}</style>"
        //data.sub_title ? data.sub_title = iosFormat+data.sub_title : data.sub_title= data.sub_title
        if(data.sub_title){
          data.sub_title = iosFormat+data.sub_title
        }
        api.post("/rest/campaings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/workfamily')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };


  let imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {

      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      // Save current cursor state
      const range = quill.getSelection(true);

      // // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
     
      // Move cursor to right side of image (easier to continue typing)
      quill.setSelection(range.index + 1);
   
      let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {

          return result[0].url;
        }
      });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  }

  const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
    const quillmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link', 'image', 'video'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { align: [] },
                    { direction: 'rtl' },
                  ],
                ['clean'],
                ['code-block'],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{ 'color': [] }, { 'background': [] }],
                [{ script: 'sub' }, { script: 'super' }, 'formula']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), [])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/workfamily">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.GROUP} help={errors.groups} validateStatus={errors.active ? 'error' : 'success'}>
                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.CODE} help={errors.code} validateStatus={errors.code ? 'error' : 'success'}>
                    <Input name="code" value={data.code} onChange={e => setData({ ...data, code: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.CATEGORY} required help={errors.category_id} validateStatus={errors.category_id ? 'error' : 'success'}>
                    <BusinessCategoryPicker record={data} setRecord={setData} name="category_id" languagesCms={languagesCms}/>
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.CITY} required help={errors.cities} validateStatus={errors.cities ? 'error' : 'success'}>
                    <CityPicker languagesCms={languagesCms} record={data} setRecord={setData} name="cities" mode="multiple" getCityById = "true"/>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.START_DATE} required help={errors.startDate} validateStatus={errors.startDate ? 'error' : 'success'}>
                    <DatePicker defaultValue={() => moment(data.startDate)} onChange={v => setData({ ...data, startDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.END_DATE} required help={errors.endDate} validateStatus={errors.endDate ? 'error' : 'success'}>
                    <DatePicker defaultValue={() => moment(data.endDate)} onChange={v => setData({ ...data, endDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item label={languagesCms.CONTENTS} help={errors.sub_title} validateStatus={errors.sub_title ? 'error' : 'success'}>
                    <ReactQuill
                      ref={quillRef}
                      modules={quillmodules}
                      formats={formats}
                      name="sub_title" theme="snow" value={(data.sub_title) ? data.sub_title : ''} onChange={content => setData({ ...data, sub_title: content })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item label={languagesCms.COVER_MEDIA} required help={errors.media} validateStatus={errors.media ? 'error' : 'success'}>
                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="media" />
                    <Alert  message={languagesCms.MEDIA_CONTENTS_MESSAGE} banner />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item label={languagesCms.MEDIA_DETAIL} required help={errors.detailMedia} validateStatus={errors.detailMedia ? 'error' : 'success'}>
                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="detailMedia" />
                    <Alert  message={languagesCms.MEDIA_CONTENTS_MESSAGE} banner />
                  </Form.Item>
                </Col>
              </Row>


              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" disabled={false} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
      </div>
    </div>
  );
};



export default CampaingDetail;
